import React from 'react';

export default function FourZeroFour() {
  return (
    <div>
      <h1>Error</h1>
      <p>Page not found</p>
    </div>
  );
}
